/* reset css */

*,
*::before,
*::after {
  outline: none;
}

/* *{
  font-family: "Plus", "Jakarta", "Sans";
} */

/* *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.craete-modal-wrapper{

left: 0;
right: 0;
top: 0;
bottom: 0;
background-color: rgba(51, 51, 51, 0.6)
} */

/* inpute webkit icon remove */
.removeDefaultIcon::-webkit-inner-spin-button,
.removeDefaultIcon::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

#multiIconNon::-webkit-inner-spin-button,
#multiIconNon::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input,
textarea,
select {
  background-color: white !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd !important;
  width: 100% !important;
  font-size: 1rem !important;
  padding: 0 16px !important;
  outline: none !important;
}

input:focus,
textarea:focus,
select:focus {
  border: 1px solid var(--primary) !important;
  /* border: 1px solid #00A35E; */
}

.none-focus:focus {
  border: none !important;
}

input,
select {
  height: 48px !important;
}

label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

label,
select,
option {
  text-transform: capitalize;
}

border-color {
  color: #e4e4e7;
}

/* pagination css  */
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  min-width: 40px !important;
  min-height: 40px !important;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  border-color: #00a35e !important;
  background-color: #e9fbf0 !important;
}

/* onboarding stepper style */
.RFS-Connector {
  border-top-width: 2px dotted black !important;
}

.RFS-StepButton {
  background: white !important;
  border: 1px solid black !important;
}

.RFS-StepButton.completed {
  background: var(--primary) !important;
  border: none !important;
}

.RFS-StepButton.completed .RFS-StepButtonContent {
  color: white !important;
}

.RFS-StepButtonContent {
  color: black !important;
}

.checkBoxControl {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  height: 10px !important;
  width: 24px !important;
  background: #d1d1d6 !important;
  transition: 0.3s;
  border-radius: 20px;
  cursor: pointer;
  border: none !important;
}

.checkBoxControl:focus {
  border: none !important;
}

.checkBoxControl:checked {
  background: #1dc9a0 !important;
}

.checkBoxControl::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background: white !important;
  transition: 0.3s;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
}

.checkBoxControl:checked:before {
  left: 16px;
}

/* checkbox-toggle */
.checkBoxToggle {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  height: 18px !important;
  width: 37px !important;
  background: white !important;
  transition: 0.3s;
  border-radius: 200px;
  cursor: pointer;
  border-color: black !important;
}

.checkbox-toggle:checked {
  border-color: #00a35e !important;
}

.checkbox-toggle:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  background: black !important;
  transition: 0.3s;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
}

.checkbox-toggle:checked:before {
  right: 1px;
  border-color: black !important;
}

.shadow-o {
  box-shadow: 0 0 5px 2px rgb(0, 0, 0);
}

#icons-button {
  border: 1px solid var(--light-grey);
  border-radius: 50%;
}

.react-calendar {
  border-radius: 5px;
  overflow: hidden;
}

/* scroll bar user profile */
.user-profile-scroll::-webkit-scrollbar {
  width: 10px;
}

.user-profile-scroll::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}

.user-profile-scroll::-webkit-scrollbar-thumb:hover {
  width: 10px;
  background-color: #b8b8b8;
}

/* user profile scroll bar end */

/* scroll bar styling */
.sidebar-scroll::-webkit-scrollbar {
  width: 5px;
  border-radius: 100px;
  background-color: #00a35e1a;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #00a35e;
}

.sidebar-scroll::-webkit-scrollbar-thumb:hover {
  width: 10px;
  background-color: #00a35e;
}

.srcoll-bar1::-webkit-scrollbar {
  width: 3px;
}

.srcoll-bar1::-webkit-scrollbar-thumb {
  background-color: #00a35e;
  border-radius: 10px;
}

/* scroll bar guest support */
.scroll-barguest::-webkit-scrollbar {
  width: 0px;
}

.scroll-barguest::-webkit-scrollbar-thumb {
  background-color: #00a35e;
  border-radius: 0px;
}

.scroll-bar2::-webkit-scrollbar {
  width: 3px;
}

.scroll-bar2::-webkit-scrollbar-thumb {
  background-color: #3333;
}

.scroll-bar3::-webkit-scrollbar {
  background-color: #fafafa;
  width: 8px;
}

.scroll-bar3::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #7a7a7a;
  width: 8px;
  height: 33px;
}

.scroll-bar4::-webkit-scrollbar {
  background-color: #ffffff;
  width: 2px;
}

.scroll-bar4::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #c1c1c1;
  width: 2px;
  height: 85px;
}

.scroll-bar5::-webkit-scrollbar {
  background-color: #ebebeb;
  width: 7px;
}

.scroll-bar5::-webkit-scrollbar-thumb {
  width: 7px;
  border-radius: 100px;
  background-color: #6e7c87;
  height: 203px;
}

/* scrolbar for multiselector */
.dropdown-scroll::-webkit-scrollbar {
  width: 7px;
  background-color: #00a35e1a;
}

.dropdown-scroll::-webkit-scrollbar-thumb {
  height: 45px;
  width: 4px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 100px;
  background-color: #00a35e;
}

.dropdown-scroll::-webkit-scrollbar-thumb:hover {
  width: 10px;
  background-color: #00a35e;
  
}

/* horizontal scrollbar */
.scroll-horizontal::-webkit-scrollbar {
  height: 7px;
  background-color: #00a35e1a;
  border-radius: 20px;
}

.scroll-horizontal::-webkit-scrollbar-thumb {
  background-color: #00a35e;
  border-radius: 2px;
}

.css-rorn0c-MuiTableContainer-root::-webkit-scrollbar {
  height: 10px;
}

.css-rorn0c-MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: #00a35e;
}

.scroll-horizontal1::-webkit-scrollbar {
  height: 7px;
  background-color: #00a35e;
}

.scroll-horizontal1::-webkit-scrollbar-thumb {
  background-color: #00a35e;
  border-radius: 5px;
}

.scroll-horizontal1::-webkit-scrollbar-thumb:hover {
  background-color: #00a35e;
}

/* scroll-horizontal 2 */
.scroll-horizontal2::-webkit-scrollbar {
  height: 5px;
  background-color: #00a35e33;
}

.scroll-horizontal2::-webkit-scrollbar-thumb {
  background-color: #00a35e;
  border-radius: 5px;
}

.scroll-horizontal1::-webkit-scrollbar-thumb:hover {
  background-color: #007041;
}

/* input type number increamentor remove */
/* Chrome, Safari, Edge, Opera */
.removeIncrementory::-webkit-outer-spin-button,
.removeIncrementory::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-scroller::-webkit-scrollbar {
  display: none;
}

/* multi carousel button css modify */
.button-modify {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.button-modify > button {
  background-color: #0000007a;
  z-index: 10;
  min-height: 35px;
  min-width: 35px;
}

.button-modify > button::before {
  font-size: 15px;
}

/* phone number input box */
.phone-number-input-box > input,
.phone-number-input-box > input:focus {
  background-color: transparent;
  border: none !important;
}

.phone-number-input-box > div {
  padding-left: 10px !important;
}

.phone-number-input-profile > input,
.phone-number-input-profile > input:focus {
  border: none !important;
  outline: none !important;
}

.backGroundeLoader div:nth-child(1) {
  animation-delay: 200ms !important;
}

.backGroundeLoader div:nth-child(2) {
  animation-delay: 400ms !important;
}

.backGroundeLoader div:nth-child(3) {
  animation-delay: 600ms !important;
}

/* material ui */
.MuiFormControl-root {
  margin: 0 !important;
  outline: none !important;
  width: 100% !important;
}

.MuiFormControl-root > label,
.MuiFormControl-root > fieldset {
  display: none !important;
}

.MuiOutlinedInput-root {
  margin: 0 !important;
  background: #ffffff !important;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
}

.MuiSelect-select {
  padding: 7px 14px !important;
  font-size: 14px !important;
  height: 24px !important;
}

.MuiPaper-elevation {
  height: fit-content;
  max-height: 220px !important;
  border-radius: 8px !important;
  background: #ffffff !important;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05) !important;
  margin-top: 8px !important;
  padding: 0 !important;
}

.MuiPaper-elevation::-webkit-scrollbar {
  width: 5px !important;
}

.MuiPaper-elevation::-webkit-scrollbar-thumb {
  background-color: #00a35e !important;
  border-radius: 8px !important;
}

.MuiPaper-elevation > ul {
  padding: 0 !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-zun73v.Mui-checked {
  color: #00a35e !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiDataGrid-cell:focus-within,
.MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  padding: 11px !important;
}

.ourTableColumns
  > div
  > div
  > .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  padding: 8px !important;
}

.reservation-bg-green {
  background: linear-gradient(
    0deg,
    rgba(51, 181, 126, 0.6),
    rgba(51, 181, 126, 0.6)
  );
  border: 2px solid #33b57e;
}

.reservation-bg-red {
  background: linear-gradient(
    0deg,
    rgba(255, 80, 80, 0.6),
    rgba(255, 80, 80, 0.6)
  );
  border: 2px solid #ff5050;
}

.reservation-bg-blue {
  background: linear-gradient(
    0deg,
    rgba(31, 93, 254, 0.6),
    rgba(31, 93, 254, 0.6)
  );
  border: 2px solid #1f5dfe;
}

.reservation-modal div > .MuiPaper-elevation {
  max-width: 100% !important;
  max-height: 90vh !important;
}

.reservation-modal div > .MuiPaper-elevation > div {
  padding: 0 !important;
}

.MuiChartsAxis-tickLabel {
  transform: rotate(45deg) !important;
}

.profile-phone-input > input {
  height: 30px !important;
  border: none !important;
  box-shadow: none !important;
}

.MuiRating-icon > .MuiSvgIcon-root {
  color: #00a35e !important;
}

/* use in settings */
.slick-slide > div {
  margin: 10px;
}

.custom-border {
  border: 1px solid #e4e4e7;
  border-top: none;
  border-left: none;
}

.custom-border:last-child {
  border-right: none;
}

/* ag-grid-react */
.ag-input-field-input {
  height: auto !important;
  padding: 4px 8px !important;
  font-weight: 400 !important;
  border-radius: 6px !important;
}

input[placeholder="Filter..."] {
  padding-left: 24px !important;
  font-size: 14px !important;
}

.ag-row-selected::before {
  background-color: var(--rgba-primary-2) !important;
}

.ag-checkbox-input-wrapper.ag-checked::after {
  color: var(--primary) !important;
}

.ag-cell {
  border: none !important;
  color: #07090d !important;
}

.ag-radio-button-input-wrapper:focus-within,
.ag-checkbox-input-wrapper:focus-within,
.ag-picker-field-wrapper:focus-within {
  border-color: var(--primary) !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
}

.ag-row:last-child {
  border-bottom: none !important;
}

.ag-radio-button-input-wrapper.ag-checked::after {
  color: var(--primary) !important;
}

.ag-header {
  background-color: white !important;
}

.ag-header-resize-cell::after {
  height: 100% !important;
}

.ag-header-cell-text {
  text-transform: capitalize !important;
  color:#6E7C87 !important;
}

.ag-filter-filter .ag-input-wrapper::before {
  display: none !important;
}

.ag-row-even,
.ag-row-odd {
  height: auto !important;
  min-height: 42px !important;
}
.ag-root ::-webkit-scrollbar {
  background-color: #f1f1f1;
  width: 7px;
  height: 7px;
}
.ag-root ::-webkit-scrollbar-thumb {
  background: #00a35e;
  width: 7px !important;
  height: 10px;
  border-radius: 20px;
}
.inspection-selector {
  padding: 0 10px !important;
  gap: 20px !important;
  border-radius: 5px 0px 0px 0px !important;
  opacity: 0px !important;
  text-transform: capitalize !important;
  border: none !important;
  box-shadow: none !important;
  height: 28px !important;
  margin-inline: auto !important;
  background: #f6f6f6 !important;
  font-size: 14px !important;
}

.inspection-selector:focus {
  border: none !important;
}

.room-cleaning-checkout {
  padding: 0 10px !important;
  gap: 20px !important;
  opacity: 0px !important;
  text-transform: capitalize !important;
  border: none !important;
  box-shadow: none !important;
  height: 28px !important;
  margin-inline: auto !important;
  font-size: 14px !important;
  background-color: transparent !important;
}

.amenity-count-select {
  padding: 0 10px !important;
  text-transform: capitalize !important;
  border: none !important;
  box-shadow: none !important;
  height: 28px !important;
  margin-inline: auto !important;
  background: transparent !important;
  font-size: 14px !important;
}

/* mobile app classes */
.scrollbar-recent-checking::-webkit-scrollbar {
  height: 6px;
  background-color: #e1dee5;
}

.scrollbar-recent-checking::-webkit-scrollbar-thumb {
  height: 4px;
  border-radius: 20px;
  width: 20px;
  background-color: #01968c;
}

.scrollbar-recent-checking::-webkit-scrollbar-thumb:hover {
  background-color: #00776d;
}

/* scrollbar-recent-checking vertical */
.scrollbar-recent-checking-vertical::-webkit-scrollbar {
  height: 6px;
  width: 0px;
  border-radius: 20px;
  background-color: #e1dee5;
}

.scrollbar-recent-checking-vertical::-webkit-scrollbar-thumb {
  height: 10px;
  border-radius: 20px;
  width: 7px;
  background-color: #01968c;
}

.scrollbar-recent-checking-vertical::-webkit-scrollbar-thumb:hover {
  background-color: #00776d;
}

/* Tutorial */
.radialGradient {
  background-image: radial-gradient(
    circle at center,
    rgba(0, 163, 94, 1) 15%,
    rgba(0, 112, 64, 1) 100%
  );
}
.scrollbar-hide {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

