@tailwind base;
@layer base {
  :root {
    /* default theme */
  }

  .theme-green {
    --primary: #00a35e;
    --secondary: hsl(6deg 100% 65%);
    --primary-light: hsl(155deg 100% 42%);
    --primary-hover: hsl(155deg 100% 22%);
    --primary-dark: hsl(155deg 100% 22%);
    --rgba-primary-1: hsl(155deg 100% 32% / 10%);
    --rgba-primary-2: hsl(155deg 100% 32% / 20%);
    --rgba-primary-3: hsl(155deg 100% 32% / 30%);
    --rgba-primary-4: hsl(155deg 100% 32% / 40%);
    --rgba-primary-5: hsl(155deg 100% 32% / 50%);
    --rgba-primary-6: hsl(155deg 100% 32% / 60%);
    --rgba-primary-7: hsl(155deg 100% 32% / 70%);
    --rgba-primary-8: hsl(155deg 100% 32% / 80%);
    --rgba-primary-9: hsl(155deg 100% 32% / 90%);
  }
  .theme-blue {
    --primary: hsl(188deg 78% 41%);
    --secondary: hsl(6deg 100% 65%);
    --primary-hover: hsl(188deg 78% 31%);
    --primary-dark: hsl(189deg 78% 11%);
    --rgba-primary-1: hsl(188deg 78% 41% / 10%);
    --rgba-primary-2: hsl(188deg 78% 41% / 20%);
    --rgba-primary-3: hsl(188deg 78% 41% / 30%);
    --rgba-primary-4: hsl(188deg 78% 41% / 40%);
    --rgba-primary-5: hsl(188deg 78% 41% / 50%);
    --rgba-primary-6: hsl(188deg 78% 41% / 60%);
    --rgba-primary-7: hsl(188deg 78% 41% / 70%);
    --rgba-primary-8: hsl(188deg 78% 41% / 80%);
    --rgba-primary-9: hsl(188deg 78% 41% / 90%);
  }
  .theme-purple {
    --primary: hsl(294deg 36% 40%);
    --secondary: hsl(6deg 100% 65%);
    --primary-hover: hsl(293deg 36% 30%);
    --primary-dark: hsl(294deg 37% 10%);
    --rgba-primary-1: hsl(294deg 36% 40% / 10%);
    --rgba-primary-2: hsl(294deg 36% 40% / 20%);
    --rgba-primary-3: hsl(294deg 36% 40% / 30%);
    --rgba-primary-4: hsl(294deg 36% 40% / 40%);
    --rgba-primary-5: hsl(294deg 36% 40% / 50%);
    --rgba-primary-6: hsl(294deg 36% 40% / 60%);
    --rgba-primary-7: hsl(294deg 36% 40% / 70%);
    --rgba-primary-8: hsl(294deg 36% 40% / 80%);
    --rgba-primary-9: hsl(294deg 36% 40% / 90%);
  }
}

@tailwind components;
@tailwind utilities;
