.kn-roombooking-chart-container {
  box-sizing: border-box;
  border: 1px solid #E4E4E7;
  background-color: white;
  
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1rem;
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--light-grey);
  border-radius: 0.5rem; 
  transition: all 0ms;
}

.kn-rbcc-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.kn-rbcc-header h3 {
  margin-bottom: 0.5rem;
  color: var(--dark-navy-blue);
  font-weight: 500;
}

/* chart width */
.rb-doughnut-chart {
  width: 250px;
  margin: 0 auto;
  transition: all 0ms;
}

.ds-salesr-com-bar canvas {
  transition: all 0ms;
}

/* header actions css */
.kn-rbcc-header-actions {
  position: relative;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  user-select: none;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--dark-navy-blue);
  background-color: var(--white);
  border: 1px solid var(--light-grey);
  transition: all 0.1s linear;
  width: 100px;
}

.kn-rbcc-header-actions:hover {
  background-color: var(--dark-cyan);
  color: var(--white);
}

.kn-rbcc-header-actions svg {
  font-size: 1rem;
  transition: 0ms;
}

.kn-rbcc-filter-option {
  display: none;
  position: absolute;
  top: 110%;
  left: 0;
  flex-direction: column;
  z-index: 10;
  padding: 0.5rem 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 0 7px 3px var(--light-black);
}

.kn-rbcc-filter-option.open {
  display: flex;
  background-color: var(--white);
}

.kn-rbcc-filter-option > label {
  padding: 0.5rem 0;
  font-size: 0.9rem;
  color: var(--black);
  padding: 0.7rem;
  cursor: pointer;
}

.kn-rbcc-filter-option > label:hover {
  background-color: var(--fresh-water);
}

.kn-rbcc-filter-option input[type="radio"] {
  display: none;
}

.kn-rbcc-header-actions svg {
  margin-left: 0.5rem;
}
/*  close header css */

@media screen and (max-width: 768px) {
  .kn-roombooking-chart-container {
    width: 100%;
  }
}

/* resetting borders */
@media screen and (max-width: 540px) {
  .kn-roombooking-chart-container {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
}
